import { Components, Theme, css } from "@mui/material";

export const button: Components<Theme>["MuiButton"] = {
  styleOverrides: {
    root: ({ theme }) => css`
      min-height: 56px;
      border-radius: ${theme.shape.borderRadius * 8}px;
      width: 100%;
      font-size: 20px;
      font-weight: 600;
      ${theme.breakpoints.down("md")} {
        font-size: 14px;
        min-height: 48px;
      }
    `,
    containedSecondary: ({ theme }) => ({
      color: theme.palette.common.white,
    }),
  },
};
