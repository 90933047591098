import { Components, Theme } from "@mui/material";

export const chip: Components<Theme>["MuiChip"] = {
  styleOverrides: {
    root: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "26px",
      letterSpacing: "0.46px",
    },
    colorSecondary: ({ theme }) => ({
      color: theme.palette.common.white,
    }),
  },
};
