import { createTheme } from "@mui/material";

import { chip } from "./componentOverrides/chip";
import { button } from "./componentOverrides/button";

declare module "@mui/material/styles" {
  interface Palette {
    white: string;
    black: string;
    lightGray: string;
    blueGray: {
      200: string;
      700: string;
      500: string;
    };
    gray: {
      900: string;
      700: string;
      400: string;
      50: string;
    };
  }
  interface PaletteOptions {
    white?: string;
    black?: string;
    lightGray?: string;
    blueGray?: {
      200?: string;
      700?: string;
      500?: string;
    };
    gray?: {
      900: string;
      700: string;
      400: string;
      50: string;
    };
  }

  interface BreakpointOverrides {
    "2xl": true;
  }
}

export const muiTheme = createTheme({
  palette: {
    white: "#fff",
    lightGray: "#eceff1",
    error: {
      main: "#f44336",
    },
    blueGray: {
      200: "#B5C8E8",
      700: "#455a64",
      500: "#607d8b",
    },
    black: "#000",
    gray: {
      "900": "#212121",
      "700": "#303030",
      "400": "#929292",
      "50": "#EBEFF8",
    },
    primary: {
      dark: "#1E4884",
      main: "#2455A2",
    },
    secondary: {
      main: "#FE683D",
    },
  },
  shape: {
    borderRadius: 4,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536,
    },
  },
  typography: {
    fontFamily: `Open Sans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji"`,
    h3: {
      fontSize: 32,
      fontWeight: 700,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: "120%",
      letterSpacing: 0.4,
    },
    body1: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: "150%",
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: button,
    MuiChip: chip,
  },
});

muiTheme.shadows[2] = "0px 8.154px 20.385px 0px rgba(30, 72, 132, 0.05)";
