import { ReactNode } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";

import { muiTheme } from "styles/muiTheme";
import { ApiClientContextController } from "context/apiClient/apiClientContextController/ApiClientContextController";

type RootLayoutProps = {
  children: ReactNode;
};

const RootLayout = ({ children }: RootLayoutProps) => (
  <MuiThemeProvider theme={muiTheme}>
    <ApiClientContextController>{children}</ApiClientContextController>
  </MuiThemeProvider>
);

export default RootLayout;
