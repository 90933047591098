import { createContext } from "react";

export type ConfigContextValueType = {
  API_URL: string;
  SPA_URL: string;
  RECAPTCHA_KEY: string;
  GOOGLE_TAG_MANAGER_ID: string;
  META_PIXEL_TRACKING_ID: string;
  SENTRY_DSN: string;
};

export const isDevelopment = process.env.NODE_ENV === "development";

export const ConfigContext = createContext<ConfigContextValueType | undefined>({
  API_URL: (isDevelopment ? process.env.API_URL : process.env.API_URL_PATTERN) as string,
  SPA_URL: (isDevelopment ? process.env.SPA_URL : process.env.SPA_URL_PATTERN) as string,
  RECAPTCHA_KEY: (isDevelopment ? process.env.RECAPTCHA_KEY : process.env.RECAPTCHA_KEY_PATTERN) as string,
  GOOGLE_TAG_MANAGER_ID: (isDevelopment
    ? process.env.GOOGLE_TAG_MANAGER_ID
    : process.env.GOOGLE_TAG_MANAGER_ID_PATTERN) as string,
  META_PIXEL_TRACKING_ID: (isDevelopment
    ? process.env.META_PIXEL_TRACKING_ID
    : process.env.META_PIXEL_TRACKING_ID_PATTERN) as string,
  SENTRY_DSN: (isDevelopment ? process.env.SENTRY_DSN : process.env.SENTRY_DSN_PATTERN) as string,
});
