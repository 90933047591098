import { ReactNode, useMemo } from "react";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ApiClientContext } from "context/apiClient/apiClientContext/ApiClientContext";
import { useConfig } from "hooks/useConfig/useConfig";

export type ApiClientControllerProps = {
  children: ReactNode;
};

export const ApiClientContextController = ({ children }: ApiClientControllerProps) => {
  const { API_URL } = useConfig();
  const queryClient = useMemo(
    () => new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, throwOnError: true } } }),
    []
  );

  const client = useMemo(
    () =>
      axios.create({
        headers: {
          "Content-Type": "application/json",
        },
        baseURL: API_URL,
      }),
    []
  );

  return (
    <ApiClientContext.Provider value={{ client }}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ApiClientContext.Provider>
  );
};
