import * as Sentry from "@sentry/gatsby";

const isDevelopment = process.env.NODE_ENV === "development";

if (isDevelopment ? !!process.env.SENTRY_DSN : !!process.env.SENTRY_DSN_PATTERN) {
  Sentry.init({
    dsn: isDevelopment ? process.env.SENTRY_DSN : process.env.SENTRY_DSN_PATTERN,
    debug: isDevelopment,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
  });
}
