import { useContext } from "react";

import { ConfigContext, type ConfigContextValueType } from "context/config/context/ConfigContext";

export const useConfig: () => ConfigContextValueType = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error("[useConfig] must be used within an ConfigContextController");
  }

  return context;
};
